import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";

export default class W9CRContractorDTO {
    public id!: number;
    public initialName!: string;
    public status = W9CRContractorStatus.NEW;
    public type: 'PERSON' | 'COMPANY' | null = null;
    public requestingCompany!: string;
    public formFiller: FormFiller | null = null;
    public customerHasForm: boolean | null = null;
    public w9Form: FileMetaDTO | null = null;
    public phone = "";
    public email = "";
    public address = new AddressDTO();

    public entityName = "";
    public entityEin = "";
    public entityOfficer = "";
    public ss4: FileMetaDTO | null = null;

    public individualFirstName = "";
    public individualLastName = "";
    public individualSsn = "";
    public individualItin = "";
    public idCard: FileMetaDTO | null = null;
    public ssnOrItin: FileMetaDTO | null = null;

    constructor(init?: Partial<W9CRContractorDTO>) {
        Object.assign(this, init);
    }

    public get isCompleted(): boolean {
        return this.status == W9CRContractorStatus.COMPLETED;
    }

    public get isCancelled(): boolean {
        return this.status == W9CRContractorStatus.CANCELLED_BY_CUSTOMER;
    }

    public get isPending(): boolean {
        return this.status == W9CRContractorStatus.FILLING_BY_CONTRACTOR
            || this.status == W9CRContractorStatus.PENDING_FOR_APPROVAL
            || this.status == W9CRContractorStatus.DOCUMENTS_PROVIDED;
    }

    public get isReadyForEmployee(){
        return this.status == W9CRContractorStatus.PENDING_FOR_APPROVAL
            || this.status == W9CRContractorStatus.DOCUMENTS_PROVIDED
            || this.status == W9CRContractorStatus.COMPLETED;
    }

    public get contractorTitle(){
        switch (this.type){
            case "COMPANY": return this.entityName?.length >= 3 ? `${this.entityName} (${this.initialName})` : this.initialName;
            case "PERSON": return this.individualFirstName?.length >= 2 && this.individualLastName?.length >= 2
                ? `${this.individualFirstName} ${this.individualLastName} (${this.initialName})` : this.initialName;
            default: return this.initialName;
        }
    }
}